@import "../../../assets/stylesheets/colors.scss";

.title {
  color: $deep-blue !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  text-align: center;
}
.container {
  margin-top: 50px;
  position: relative;
  svg {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .cardTitle {
    color: $deep-blue !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 28px; /* 155.556% */
  }
  .cardText {
    color: $grey-blue !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px; /* 150% */
    span {
      color: $purple !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 24px;
    }
  }
}

@media (max-width: 599px) {
  .container {
    flex-direction: column;
    min-width: 300px;

    .formControl {
      margin-bottom: 30px;
      min-width: 300px !important;
    }
  }
}
