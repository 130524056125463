@import "../../../../assets/stylesheets/colors.scss";

.card {
  border-radius: 10px;
  border: 1px solid $light-grey-alt;
  align-items: center;

  .header {
    padding: 32px 20px 0px 20px;
    display: flex;
    text-align: start;
    justify-content: space-between;

    h1 {
      color: $purple !important;
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
    img {
      cursor: pointer;
    }
  }

  h1 {
    color: $purple;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .image {
    padding: 24px 20px 0px 20px;
    img {
      width: 100%;
      max-height: 161px;
      flex-shrink: 0;
    }
  }

  p,
  span {
    color: $custom-grey;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & span {
      color: $deep-blue;
      font-weight: 600;
    }
  }

  .btn {
    padding: 16px 20px;
    > button {
      min-width: 100%;
    }
  }
}
