@import "../../../../assets/stylesheets/colors.scss";

.avatarError {
  border: 1px solid $light-grey-alt;
  align-items: center;

  .header {
    padding: 32px 20px 0px 20px;
    display: flex;
    text-align: start;
    justify-content: center;

    p {
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
  }
}

.card {
  border: 1px solid $light-grey-alt;
  align-items: center;

  .header {
    padding: 32px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      color: $purple !important;
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
  }
  img {
    padding-top: 24px;
    width: 100%;
    max-width: 286px;
    max-height: 161px;
  }

  p {
    color: $grey-blue !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
  h2 {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; /* 142.857% */
  }
}

.btn {
  p {
    padding: 16px 20px 0px 20px;
    color: $custom-grey !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
  border-top: 1px solid $light-grey-alt !important;
}
