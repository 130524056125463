@import "../../../../assets/stylesheets/colors.scss";

.card {
  border: 1px solid $light-grey-alt;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    padding: 32px 20px 0px 20px;
    display: flex;
    text-align: start;

    h1 {
      color: $purple !important;
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
  }

  .roomImg {
    padding: 24px 20px 0px 20px;
    width: 100%;
    max-height: 112px;
    object-fit: cover;
  }

  h2 {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; /* 142.857% */
  }

  .roomDate {
    p {
      color: $deep-blue !important;
      line-height: 160% !important; /* 22.4px */
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
    }
  }
}

@media (max-width: 599px) {
  .card {
    width: fit-content !important;
  }
}

@media (max-width: 399px) {
  .card {
    min-width: 100%;
  }

  .footerButtons {
    display: flex;
    flex-wrap: wrap;
  }

  .footerButtons > :first-child {
    margin-bottom: 10px;
  }

  .footerButtons > * {
    flex: 0 0 100%;
  }
}
