@import "../../../assets/stylesheets/colors.scss";

.activeMenuBtn {
  border-radius: 4px;
  background: $lighter-grey-alt !important;
}

.menuBtn {
  text-decoration: none;
  width: 100%;
  color: $purple !important;
  div {
    background: inherit;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
  }
}
.menuBtn:hover {
  text-decoration: none;
  div {
    background: inherit;
  }
}
.signoutBtn {
  color: $purple !important;
  justify-content: center !important;
  font-size: 16px;
  font-weight: 600;
}

.header {
  min-height: 42px;
  box-shadow: 0px 1px 1px $light-grey-alt;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  .content {
    width: 85% !important;
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    padding-bottom: 8px;
    a {
      img {
        margin-right: 32px;
        width: 34px;
        height: 36px;
        flex-shrink: 0;
      }
    }

    .group {
      a {
        :hover {
          border-radius: 6px;
          background: $lighter-grey-alt !important;
        }
        button {
          background: none !important;
          box-shadow: none;
        }
      }
      button,
      p {
        max-height: 36px !important;
        padding: 12px 12px 12px 12px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        color: $custom-grey !important;
        align-items: center !important;
        text-transform: capitalize !important;
      }

      .btn {
        a {
          button {
            color: $purple !important;
          }
        }
      }

      .activeBtn {
        button {
          background: $lighter-grey-alt !important;
          color: $deep-blue !important;
        }
      }
      .activeBtnAbout {
        button {
          background: $new-purple !important;
          color: $purple-dark !important;
        }
      }
    }

    .login {
      .menuIcon {
        display: none;
      }

      .loginBtn {
        img {
          max-width: 22px;
          max-height: 22px;
          padding-bottom: 2px;
        }
      }
      a {
        color: $custom-grey;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      a:hover {
        text-decoration: none;
        color: $purple;
      }
    }
  }
}

@media (max-width: 899px) {
  .header:not(.menuOpen) {
    .group {
      display: none;
    }
    .login {
      .loginAvatars {
        display: flex;
      }
      .menuIcon {
        display: block !important;
      }
    }
  }

  .header.menuOpen {
    .group {
      display: none;
    }
    .login {
      .loginAvatars {
        display: none;
      }
      .menuIcon {
        display: block !important;
      }
    }
  }
}
@media (max-width: 499px) {
  .menuIcon {
    padding-right: 10px !important;
  }
  .loginAvatars {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .content {
    margin: 0px !important;
    padding: 5px !important;
    padding-right: 10px !important;
    min-width: 100% !important;
  }
}
