@import "../../assets/stylesheets/colors.scss";
.title {
  width: 100%;
  color: $deep-blue !important;
  font-size: 18px !important;
  padding: 24px;
  padding-top: 20px;
  padding-bottom: 8px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  text-align: center;
}

.secondaryText {
  color: $grey-blue !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
}

.inputWithLabel {
  width: 100%;
  margin-bottom: 10px;
  p {
    color: $grey-blue;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    border-radius: 6px;
    padding: 9px 13px 9px 13px;
  }
}
