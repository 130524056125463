$white-transparent: rgba(255, 255, 255, 0.9);
$off-white: #fafafa;
$white: #fff;
$darkest-grey-transparent: rgba(32, 32, 32, 0.9);
$grey-text: rgba(127, 127, 127, 1);
$dark-grey-text: rgba(64, 64, 64, 1);
$light-text: rgba(240, 240, 240, 1);
$lighter-grey: #f0f0f0;
$light-grey: lightgrey;
$dark-grey: rgba(128, 128, 128, 1);
$darker-grey: rgba(64, 64, 64, 1);
$darkest-grey: rgba(32, 32, 32, 1);
$bright-blue: #2f80ed;
$light-blue: #6fc0fd;
$semi-bold: 600;
$duck-yellow: #ffc000;
$purple: #931ffa;
$purple-dark: #8005ed;
$deep-blue: #203b54;
$dark-blue: #364f65;
$grey-blue: #4d6276;
$light-grey-alt: #d1d5db;
$lighter-grey-alt: #f3f6f7;
$custom-grey: #6b7280;
$new-purple: #ebdafa;
$pink: #eee8f0;
$custom-pink: rgba(207, 160, 223, 0.12);
$dark: #111827;
$form-gray: #f9fafb;
$form-scroll: #d2d8dd;
$custom-green: #065f46;
$custom-mint: #d1fae5;
$custom-pale-yellow: #fef3c7;
$custom-brown: #92400e;
$pink-100: #fce7f3;
$pink-800: #9d174d;
$background-custom-pink: rgba(207, 160, 223, 0.4);
$gray-600: #4b5563;
$Help-Text: #798998;

//userStatiscticCards
$pink-50: #ffe3fc;
$lavender: #eee3ff;
$lightBlue: #e3e9ff;
$lightAqua: #e3f5ff;
$lightCyan: #e3fffc;
$lightGreen: #ecffe3;
