@import "../../assets/stylesheets/colors.scss";

$colors: $pink-50, $lavender, $lightBlue, $lightAqua, $lightCyan, $lightGreen;

@for $i from 1 through 6 {
  .item-#{$i} {
    background-color: nth($colors, $i);
    transition: outline 0.3s;

    &:hover {
      outline: 1px solid $purple;
    }
  }
}
.defaultUserLogo {
  width: 40px;
  height: 40px;
  background-color: $dark-blue;
  color: $white-transparent;
  font-size: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineChartBox {
  background-color: #f7f9fb !important;
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px 16px 24px 16px;
}

.chartBox {
  display: flex;
  justify-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.chartWrraper {
  width: 100%;
  height: 20vh;
}

@media (max-width: 499px) {
  .lineChartBox {
    display: flex;
    justify-content: flex-start;
    p {
      margin: 0;
    }
  }
  .chartWrraper {
    flex-wrap: wrap;
    height: 50vh;
  }
}
