@import ".././../assets/stylesheets/colors.scss";

.text {
  color: $custom-green !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  display: inline;
}

.owner {
  background: $custom-mint !important;
  p {
    color: $custom-green !important;
  }
}

.member {
  background: $custom-pale-yellow !important;
  p {
    color: $custom-brown !important;
  }
}

.title {
  color: $deep-blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px; /* 142.857% */

  span {
    color: $deep-blue !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 399px) {
  .container {
    button {
      margin-top: 10px !important;
    }
  }
}
