@import "_theme.scss";
@import "common.scss";

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

input[type="search"]::-webkit-search-cancel-button {
  /* https://stackoverflow.com/a/55170420 */
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

.discreet {
  color: $dark-gray;
}

.alert-color {
  color: #e0a800;
}

.home-anonymous {
  background: center no-repeat url("/assets/room_bg.webp");
}

.errors {
  color: $red;
  margin: 1.5rem 0;
  padding: 1.5rem;
  background: white;
  border: 1px dashed $red;
  text-align: center;

  h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .message {
    font-weight: 600;
  }
}
