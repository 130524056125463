@import "../../assets/stylesheets/colors.scss";

/* Settings Page */

.settingPageContainer {
  display: flex !important;
  flex-direction: row !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  .title {
    color: $deep-blue !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .text {
    font-size: 14px !important;
    color: $grey-blue;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .tabs {
    button {
      min-height: 40px !important;
      margin-bottom: 16px;
      color: $gray-600;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 20px;
      border-radius: 6px;
    }
  }

  .activeTab {
    color: $white !important;
    background-color: $purple-dark !important;

    .svgWithFill {
      svg * {
        fill: $white !important;
      }
    }

    .svgWithStroke {
      svg {
        path {
          stroke: $white !important;
        }
      }
    }
  }

  .defaultUserLogo {
    width: 40px;
    height: 40px;
    background-color: $dark-blue;
    color: $white-transparent;
    font-size: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 899px) {
  .settingPageContainer {
    display: flex !important;
    flex-direction: column !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    max-width: fit-content !important;
    max-height: 350px;
    margin-bottom: 20px;
  }

  .mainContentContainer {
    padding: 0 !important;
  }
}

//Dashboard page

.dashboardContainer {
  .dashboardBackButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
  }

  @media (max-width: 899px) {
    .dashboardBackButton {
      justify-content: flex-start !important;
    }
  }
  @media (max-width: 650px) {
    .dashboardBackButton {
      margin-top: 60px;
    }
  }
  @media (max-width: 399px) {
    .dashboardHeaderBox {
      padding: 16px !important;
    }
  }
}
