@import "./colors.scss";

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 95%;
  max-width: 450px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    width: 100%;
    color: $deep-blue !important;
    font-size: 18px !important;
    padding: 8px 20px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    text-align: center;
  }

  .secondaryText {
    color: $grey-blue !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; /* 142.857% */
  }

  .actions {
    display: flex;
    padding-top: 20px;
    width: 100%;
    gap: 20px;
  }

  .validateButton {
    width: 100%;
    min-width: 80px;
  }

  .cancelButton {
    width: 100%;
  }
}
