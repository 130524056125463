@import "../../assets/stylesheets/colors.scss";

.defaultUserLogo {
  width: 32px !important;
  height: 32px !important;
  background-color: $dark-blue !important;
  color: $white-transparent !important;
  font-size: 14px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
