@import "../../assets/stylesheets/colors.scss";

.header {
  padding: 14px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .text {
    padding-bottom: 8px;
    padding-top: 8px;
    align-items: center;
    margin-right: auto;
    color: $purple !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 140% !important; /* 33.6px */
    white-space: nowrap !important;
  }

  .btnGroup {
    p {
      color: $purple !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
  a {
    :hover {
      background: $pink;
    }
  }
}

.defaultUserLogo {
  width: 30px !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  height: 30px !important;
  background-color: $dark-blue !important;
  color: $white-transparent !important;
  font-size: 14px !important;
  border-radius: 50% !important;
}

.info {
  p {
    color: $grey-blue !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .discreet {
    font-size: 12px;
    font-weight: normal;
  }

  .infoHeader {
    .text {
      color: $purple !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
  }
}
