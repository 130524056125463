@import "../../../assets/stylesheets/colors.scss";
.title {
  color: $deep-blue !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  text-align: center;
}

.text {
  color: #798998 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
}

.numberCard {
  color: $deep-blue !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.modalFooter {
  padding-top: 20px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  a {
    text-decoration: none !important;
  }
}
@media (max-width: 599px) {
  .modalFooter {
    justify-content: flex-start !important;
  }
}
