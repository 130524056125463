@import "../../assets/stylesheets/colors.scss";

// Define a mixin for the common properties of circles
@mixin circle-commons {
  position: relative;
  width: 45px;
  height: 40px;
  border: 2px solid $light-grey-alt;
  border-radius: 20px;
  margin: 20px 16px;
}

.container {
  width: 100% !important;
  border-radius: 12px;
  border: 1px solid $light-grey-alt;
  min-height: 100%;

  .header {
    padding: 0px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .text {
      padding-bottom: 16px;
      padding-top: 16px;
      align-items: center;

      flex-grow: 1;
      color: $purple !important;
      font-size: 24px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 140% !important; /* 33.6px */
      white-space: nowrap !important;
    }

    .createLink {
      max-height: 38px;
      width: 100%;
      justify-items: flex-end;
    }
  }

  .stepsSections {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .stepBox,
    .activeStep,
    .stepDone {
      display: flex;
      width: 50%;
      height: 80px;
      border: 1px solid $light-grey-alt;
      border-left: 0;
      border-right: 0;
      align-items: center;
      line-height: 16px !important; /* 133.333% */
      text-transform: uppercase !important;

      .circle {
        @include circle-commons;

        .circleText {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $custom-grey;
          font-size: 16px;
          font-weight: bold;
        }
      }

      P {
        color: $grey-blue;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 600;
        line-height: 16px !important; /* 133.333% */
        letter-spacing: 0.3px !important;
        text-transform: uppercase;
      }

      span {
        color: $custom-grey !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        text-transform: none !important;
      }
    }

    .activeStep {
      border-bottom: 3px solid $purple;
      img {
        max-height: 78px;
      }
      p {
        color: $purple;
      }

      .circle {
        border-color: $purple;

        .circleText {
          color: $purple;
        }
      }
    }

    .stepDone {
      p {
        color: $dark;
      }
    }
  }
}

@media (max-width: 899px) {
  .container {
    .header {
      justify-content: space-between;
      .text {
        flex-grow: 0;
      }
    }
  }
}

@media (max-width: 710px) {
  .container {
    .header {
      flex-direction: column;
      div {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 5px;
        button {
          max-width: 220px;
        }
      }
      align-items: start;

      .btnGroup {
        min-height: 100%;

        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 399px) {
  .container {
    .header {
      div {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 5px;
      }
      align-items: start;
      .btnGroup {
        flex-wrap: wrap;
        display: flex;
        .text {
          margin-bottom: 10px;
        }
        p {
          font-size: small;
        }
      }
    }

    .stepsSections {
      .activeStep {
        img {
          height: 100%;
        }
        min-height: 141px;
      }

      .stepBox {
        img {
          height: 100%;
        }
        min-height: 141px;
      }
    }
  }
}
