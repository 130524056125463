@import "../../../assets/stylesheets/colors.scss";

.title {
  color: $deep-blue !important;
  font-size: 24px !important;

  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  text-align: center;
}

.defaultCard {
  border-radius: 16px;
  background: #75019c;
  p {
    color: $white !important;
  }
  svg {
    color: $white !important;
  }
}

@media (max-width: 399px) {
  form {
    .startDate {
      margin-bottom: 60px;
    }
  }
}

.corporateModal {
  a {
    text-decoration: none !important;
  }

  .corporateModalTitle {
    width: 100%;
    color: $deep-blue !important;
    font-size: 18px !important;
    padding-bottom: 8px;
    padding-top: 20px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    text-align: center;
  }

  .secondaryText {
    color: $grey-blue !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; /* 142.857% */
  }
}

.frame {
  div {
    display: none !important;
  }
}

@media (max-width: 399px) {
  .inputWithLabel {
    min-width: 300px !important;
  }

  .modalFooter {
    justify-content: flex-start !important;
  }
}
