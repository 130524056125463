@import "../../../../assets/stylesheets/colors.scss";

.card {
  border: 1px solid $light-grey-alt;
  align-items: center;

  .header {
    padding: 32px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      color: $purple !important;
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
  }
}
