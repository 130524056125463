@import "../../../assets/stylesheets/colors.scss";
.title {
  color: $deep-blue !important;
  font-size: 24px !important;

  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  text-align: center;
}

.inputWithLabel {
  width: 100%;
  margin-bottom: 10px;
  p {
    color: $grey-blue;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    border-radius: 6px;
    padding: 9px 13px 9px 13px;
  }
}

.modalFooter {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  a {
    text-decoration: none !important;
  }
}
.cardBox {
  display: flex;
}

.issuedBox {
  background-color: #eee3ff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  p {
    color: $deep-blue !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 36px !important; /* 150% */
  }
}

.avoidedBox {
  background-color: #ccacff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  p {
    color: $deep-blue !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 36px !important; /* 150% */
  }
}

@media (max-width: 599px) {
  .inputWithLabel {
    min-width: 300px !important;
    margin-right: 0 !important;
    margin-bottom: 40px !important;
    max-height: 41px !important;
  }
  .cardBox {
    display: block;
  }
  .avoidedBox,
  .issuedBox {
    border-radius: 12px;
    margin-bottom: 10px;
  }

  .modalFooter {
    justify-content: flex-start !important;
  }
}
