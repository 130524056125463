@import ".././../assets/stylesheets/colors.scss";
.container {
  button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    max-height: 38px !important;
    /* 142.857% */
  }
}

.text {
  color: $custom-green !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  display: inline;
}

.owner {
  background: $custom-mint !important;
  p {
    color: $custom-green !important;
  }
}

.member {
  background: $custom-pale-yellow !important;
  p {
    color: $custom-brown !important;
  }
}

.title {
  color: $deep-blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px; /* 142.857% */
  span {
    color: $deep-blue !important;
    font-weight: 400 !important;
  }
}

.secondaryText {
  color: $grey-blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px; /* 142.857% */
}

.manageBtn {
  width: 104px;
  color: $white;
  font-size: 14px;
}

@media (max-width: 899px) {
  .wrapper {
    margin: 0 !important;
    flex-direction: column !important;
  }
}

@media (max-width: 599px) {
  .wrapper {
    p {
      width: 150px;
    }
  }
  .container {
    flex-direction: column !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 399px) {
  .modalBtnBox {
    flex-wrap: wrap;
    button {
      margin: 0;
      margin-top: 10px;
    }
  }
}
