@import "../../assets/stylesheets/colors.scss";
.title {
  color: $deep-blue !important;
  text-align: center;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 28px; /* 155.556% */
}

.subtitle {
  color: $grey-blue !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
}

.text {
  color: $Help-Text !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px; /* 142.857% */
}

.numberCard {
  color: $deep-blue !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.modalFooter {
  padding-top: 100px;
  padding-right: 24px;
  display: flex;
  justify-content: center;
  min-height: 80px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  a {
    text-decoration: none !important;
  }
}

@media (max-width: 599px) {
  .modalFooter {
    justify-content: flex-start;

    button {
      margin-bottom: 10px;
    }
  }
}
