@import "../../assets/stylesheets/colors.scss";

.formBox {
  button {
    border-radius: 6px 0px 0px 6px;
    border: 1px solid $light-grey-alt !important;
    background: $white;
    svg {
      color: #9ca3af;
    }

    &:hover {
      background: $lighter-grey-alt !important;
    }
  }

  p {
    color: $deep-blue !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .formIncBtn {
    border-radius: 0px 6px 6px 0px !important;
    border: 1px solid $light-grey-alt !important;
  }

  .formText {
    padding-top: 5px !important;
    color: $deep-blue !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center;
    border-top: 1px solid $light-grey-alt !important;
    border-bottom: 1px solid $light-grey-alt !important;
    cursor: pointer;
  }
}
.menuBtn {
  width: 97px;
  height: 30px;
  padding: 5px 13px 5px 17px;
  text-align: center;
  border-radius: 6px !important;
  border: 1px solid $light-grey-alt !important;
  background: $white !important;
  color: $deep-blue !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;

  &:hover {
    background: $lighter-grey-alt !important;
  }
}
