@import "_theme.scss";
@import "./stylesheets/colors.scss";

@font-face {
  src: url("../../public/fonts/EuclidCircularB-Medium.woff2") format("woff2");
  font-family: "Euclid Circular B";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../../public/fonts/EuclidCircularB-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/EuclidCircularB-SemiBold.woff2") format("woff2");
  font-family: "Euclid Circular B";
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "Euclid Circular B", sans-serif !important;
  color: $deep-blue !important;
}

/* used for chat input */
textarea {
  font-family: "Euclid Circular B", sans-serif;
}

a {
  color: $primary;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: $primary;
}

label {
  color: $grey-blue;
  font-weight: 500;
  line-height: 1.5;
}

.card-outfit {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: auto;
  max-width: 204px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: $box-shadow;
  padding: 0;
  margin: 1px;
  cursor: pointer;
  text-align: center;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid $primary;
  }

  &.card-temp-selected {
    border: 2px solid $primary;
  }

  &.card-selected {
    border: 2px solid $dark-gray;
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
  }

  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.outfits-grid {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-height: 82vh; // desktop
  overflow-y: scroll;
}

.avatarPreview {
  border: none;
  width: 100%;
  height: 240px;
}

.outfit-avatar-preview .avatarPreview {
  width: 100%;
  height: 600px;
}

@media (max-height: 768px) {
  // iPad
  .outfits-grid {
    max-height: 70vh;
  }
  .outfit-avatar-preview .avatarPreview {
    width: 100%;
    height: 450px;
  }
}

@media (max-width: 575px) {
  // mobile
  .outfits-grid {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .outfits-grid .card-outfit {
    scroll-snap-align: center;
  }

  .outfit-avatar-preview .avatarPreview {
    width: 100%;
    min-height: 200px;
    height: auto;
  }
}

.table {
  color: #203b54;
  margin-bottom: 1rem;
  width: 100%;
  border-collapse: collapse;
}
.table td,
.table th {
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table .thead-dark th {
  background-color: #343a40;
  border-color: #454d55;
  color: #ffffff;
}
