@import "../../../../assets/stylesheets/colors.scss";

.card {
  border: 1px solid $light-grey-alt;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    padding: 32px 32px 0px 32px;
    display: flex;
    text-align: start;
    justify-content: center;

    h1 {
      color: $purple !important;
      text-align: start !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 16px !important;
    }
  }

  p {
    color: $grey-blue !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
  h2 {
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important; /* 142.857% */
  }
}
