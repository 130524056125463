@import "../../../assets/stylesheets/colors.scss";

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  width: 100%;
  background: $form-gray !important;
  color: $deep-blue !important;
  font-size: 18px !important;
  padding: 24px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: 1px solid $form-gray;

  svg {
    width: 20px;
    height: 20px;
  }
}
