@import "../../assets/stylesheets/colors.scss";
@import "../../assets/stylesheets/modal.module.scss";

.logoContainer {
  svg {
    width: 16px;
    height: 16px;
  }
}

.subscriptionLink {
  color: $deep-blue !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  a {
    text-decoration: none;
  }
}
