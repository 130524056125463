@import "./colors.scss";

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  border-radius: 20px;
  max-height: 100%;

  form {
    overflow-y: auto;
    max-height: 75vh;

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #d2d8dd;
    }

    &::-webkit-scrollbar {
      width: 4px;
      max-height: 64px;
      flex-shrink: 0;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $form-gray;
    color: $deep-blue !important;
    font-size: 18px !important;
    padding: 18px 24px;
    font-style: normal;
    font-weight: 600 !important;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .text,
  .label {
    color: $grey-blue !important;
    font-weight: 500 !important;
  }

  .explanation {
    color: $grey-blue !important;
    padding: 8px 0;
    flex-basis: 100%;
  }

  .inlineField {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    flex-wrap: wrap;
  }

  .field {
    margin-bottom: 24px;
    label {
      display: block;
    }
  }

  .modalFooter {
    padding: 18px 24px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .uploadBox {
    span {
      color: $deep-blue !important;
    }
    &:hover {
      border-color: $purple;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 140px;
    border: 2px dashed #d1d5db;

    .title {
      color: $purple !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      span {
        color: $deep-blue !important;
      }
    }

    .helpedText {
      color: $custom-grey;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }
}
