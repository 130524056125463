@import "../../assets/stylesheets/colors.scss";

.langBtnGroup {
  display: flex;
  justify-content: center;

  :first-child {
    border: 1px solid $light-grey-alt;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  :last-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .langBtn,
  .langActiveBtn {
    border-top: 1px solid $light-grey-alt;
    border-right: 1px solid $light-grey-alt;
    border-bottom: 1px solid $light-grey-alt;
    border-radius: 8px;
    padding: 8px 16px;

    svg {
      height: 1rem;
      border: none;
    }
  }

  .langBtn {
    background: $white-transparent;
  }

  .langActiveBtn {
    background: $light-text !important;
  }
}
