@import "../../assets/stylesheets/colors.scss";

.title {
  color: $deep-blue !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
  text-align: center;
}

.text {
  color: $deep-blue !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 133.333% */

  span {
    color: $purple !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
  }
}
