@import "../../../assets/stylesheets/colors.scss";
.title {
  color: $deep-blue !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
}

.price {
  color: $deep-blue !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 28px;

  span {
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 48px;
    letter-spacing: -1.2px;
  }
}

.text {
  color: $custom-grey !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 150% */
}

.values {
  color: $deep-blue !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px; /* 150% */
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  a {
    text-decoration: none !important;
  }
}
@media (max-width: 1299px) {
  .typeContainer {
    display: block !important;
  }
  .modalFooter {
    justify-content: flex-start !important;
    button {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 399px) {
  .typeContainer {
    display: block !important;
    padding: 10px !important;
  }
  .modalFooter {
    justify-content: flex-start !important;
    button {
      margin-bottom: 10px;
    }
  }
}
