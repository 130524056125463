@import ".././../assets/stylesheets/colors.scss";

.title {
  color: $deep-blue !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 32px;
}

.subTitle {
  color: $deep-blue !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
}

.text {
  color: $custom-grey !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16px; /* 133.333% */
}

.uploadIconButton {
  background-color: #f2f4f8 !important;
  &:hover {
    background-color: #f0f1f8 !important;
  }
}
.inputWithLabel {
  width: 100%;
  margin-bottom: 10px;
  p {
    color: $grey-blue;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    border-radius: 6px;
    padding: 9px 13px 9px 13px;
  }
}

.modalFooter {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  gap: 10px 20px;
  a {
    text-decoration: none !important;
  }
}

@media (max-width: 399px) {
  .inputWithLabel {
    min-width: 300px !important;
  }

  .modalFooter {
    justify-content: flex-start !important;
  }
}
